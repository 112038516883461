import React, { useState, useEffect } from "react";
import { REGEXS_NEW_PASS } from "../utils";
import { BASE_URL } from "../properties";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from 'react-redux';
import { encryptAES } from '../utils'
import check from '../assets/check_img.svg';
import M from 'materialize-css';

function FormNewPass({callBack}) {
    const appData = useSelector((state) => state.appData)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        passwordAnterior: "",
        password: "",
        passwordCopy: "",
        errorpassword: false,
        errorpasswordCopy: false,
        showPassword: false,
        showPasswordCopy: false,
        loading: false,
        REGEX_CHECK: REGEXS_NEW_PASS,
        errors: [],
        email: '',
        palabrasRestringidas: []
    })

    const [toogleEye, setToogleEye] = useState({ toogleA: false, toogle1: false, toogle2: false })
    const [success, setSuccess] = useState(false)

    useEffect(() => {
        document.body.style.minHeight = '1000px'

        if (window.navigator.userAgent.indexOf("Edg") > -1) {
            let ojos = document.getElementsByClassName('field-icon');

            if(ojos.length){
                for(let ojo of ojos){
                    ojo.style.display = 'none';
                }
            }
      }

        return () => {
            document.body.style.minHeight = ''
        };
    }, []);

    useEffect(() => {
        M.updateTextFields();
    }, [data])

    useEffect(() => {
        if (success) {
            setToogleEye({ toogleA: false, toogle1: false, toogle2: false })
        }
    }, [success])


    const sendData = (e) => {
        e.preventDefault();
        setLoading(true);

        let dataToEncrypt = {
            //"UsuarioServicio": "AdminServicios",
            //"ContraseniaServicio": "Prueba02",
            "Usuario": appData.user.usuarioLogin,
            "IdUsuario": `${appData.user.IdUsuario}`,
            "Sesion": appData.user.Sesion,
            "CambioContrasenia": data.passwordAnterior,
            "ContraseniaNueva1": data.password,
            "ContraseniaNueva2": data.passwordCopy,
            "DeviceToken": "",
            "SistemaOperativo": "ios",
            "AppID": "SAF_APP",
            "token": appData.tokenSesion,
            "refresh_token": appData.token.refresh_token
        }

    

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': appData.idToken
            },
            body: JSON.stringify({ DatosEncriptados: encryptAES(dataToEncrypt) })
        };

        fetch(`${BASE_URL}/loggin/CambioContrasenia`, requestOptions)
            .then(response => response.json())
            .then((data) => {
              
                if (data.BanderaExito) {
                    setSuccess(true)
                } else {
                    if(data.Mensaje === 'No existe sesion activa.' || data.Mensaje === 'Error al validar jwt. El token ingresado es inválido'){
                        return {sinSesion:true}
                    } else{
                       
                        M.toast({ html: data.Mensaje, classes:'red accent-4 white-text txtBold' })
                    }
                }

                setLoading(false);

            }).catch((e) => {
                setLoading(false);

            });
    }

    const aplica_reglas = () => {
        if(data.passwordAnterior === "" && data.password === ""){
            return true
        }

        return false;
    }

    const onChangeData = (event) => {
        
        let { id, value } = event.target;

        let { password, passwordCopy, REGEX_CHECK, palabrasRestringidas } = data;
        let errors = [];
        let errorpasswordCopy = false;
        let errorpassword = false;

        const filtroPalabras = palabrasRestringidas.filter((v) => {
            return value.includes(v);
        });

        if (filtroPalabras.length) {

            errorpassword = `No debe incluir 
                ${filtroPalabras.length > 1 ? `las palabras ${filtroPalabras.join(',')}`
                    : `la palabra ${filtroPalabras.join('')} `}`
        } else {
            if (id === "password") {
                if (value.trim() !== passwordCopy.trim()) {
                    errorpasswordCopy = "Las contraseñas no coinciden.";
                }
                password = value;
            } else if (id === "passwordCopy") {
                if (value.trim() !== password.trim()) {
                    errorpasswordCopy = "Las contraseñas no coinciden.";
                }
                passwordCopy = value;
            }
        }

        REGEX_CHECK.forEach((v, i) => {
            REGEX_CHECK[i].isCorrect = password.trim() !== "" ? v.validator(password) : false;

            if (REGEX_CHECK[i].isCorrect) {
                errors.push(REGEX_CHECK[i].isCorrect);
            } else {
                errorpassword = true;
            }

            // if(REGEX_CHECK[i].isCorrect ){
            //     REGEX_CHECK[i].isCorrect = passwordCopy.trim() !== "" ? v.validator(passwordCopy) : false;
            //     if (REGEX_CHECK[i].isCorrect) {
            //         errors.push(REGEX_CHECK[i].isCorrect);
            //     } else {
            //         errorpassword = true;
            //     }
            // }
            
        });

        setData({
            ...data,
            password,
            passwordCopy,
            errorpassword,
            errorpasswordCopy,
            REGEX_CHECK,
            errors
        })

    }

    return (
        <form onSubmit={sendData} className="row">
            <div className="input-field col s12">
                <input type={toogleEye.toogleA ? 'text' : 'password'} name='passwordAnterior' id='passwordAnterior'
                    value={data.passwordAnterior}
                    onChange={(e) => {
                        setData({ ...data, passwordAnterior: e.target.value })
                    }}
                    disabled={success}
                />
                <label htmlFor='passwordAnterior' >Contraseña anterior</label>
                <span onClick={() => {
                    if (!success) {
                        setToogleEye({ ...toogleEye, toogleA: !toogleEye.toogleA })
                    }
                }} className="field-icon toggle-password">
                    <span className="material-icons">
                        {
                            toogleEye.toogleA ?
                                'visibility_off' : 'visibility'
                        }
                    </span>
                </span>
            </div>

            <div className="input-field col s12">
                <input type={toogleEye.toogle1 ? 'text' : 'password'} name='password' id='password'
                    className={data.errorpassword ? 'inputErr' : ''}
                    value={data.password}
                    onChange={onChangeData}
                    disabled={success}
                    maxLength={12}
                />
                <label htmlFor='password' className={data.errorpassword ? 'lblErr' : ''}>Nueva contraseña</label>
                <span onClick={() => {
                    if (!success) {
                        setToogleEye({ ...toogleEye, toogle1: !toogleEye.toogle1 })
                    }
                }} className="field-icon toggle-password">
                    <span className="material-icons">
                        {
                            toogleEye.toogle1 ?
                                'visibility_off' : 'visibility'
                        }
                    </span>
                    {
                        data.errorpassword ?
                            <span className="material-icons red-text">
                                error
                            </span> : null
                    }
                </span>
            </div>

            <div className="input-field col s12">
                <input type={toogleEye.toogle2 ? 'text' : 'password'} name='passwordCopy' id='passwordCopy'
                    value={data.passwordCopy}
                    className={data.errorpasswordCopy ? 'inputErr' : ''}
                    onChange={onChangeData}
                    disabled={success}
                    maxLength={12}
                    onPaste={(e)=>e.preventDefault()}
                />
                <label htmlFor='passwordCopy' className={data.errorpasswordCopy ? 'lblErr' : ''}>Confirmar contraseña</label>
                {
                    data.password !== data.passwordCopy ? 
                    <span className="helper-text left red-text darken-3">Las contraseñas no coinciden</span>

                    : null

                }
                
                <span onClick={() => {
                    if (!success) {
                        setToogleEye({ ...toogleEye, toogle2: !toogleEye.toogle2 })
                    }
                }} className="field-icon toggle-password">
                    <span className="material-icons">
                        {
                            toogleEye.toogle2 ?
                                'visibility_off' : 'visibility'
                        }
                    </span>

                    {
                        data.errorpasswordCopy ?
                            <span className="material-icons red-text">
                                error
                            </span> : null
                    }


                </span>
            </div>

            {
                success ?
                    <React.Fragment>
                        <div className="col s12 mt-2 center-align">
                            <img src={check} alt="success" className="successResetPass" />
                        </div>
                        <div className="col s12 mt-1">
                            <span className="ft-16 primaryText">Se ha restablecido la contraseña</span>
                        </div>
                        <div className="col s12 mt-2">
                            <button type="button" className="btn btn-webSi-primary waves-effect light"
                                onClick={() => {

                                    setData({
                                        passwordAnterior: "",
                                        password: "",
                                        passwordCopy: "",
                                        errorpassword: false,
                                        errorpasswordCopy: false,
                                        showPassword: false,
                                        showPasswordCopy: false,
                                        loading: false,
                                        REGEX_CHECK: REGEXS_NEW_PASS,
                                        errors: [],
                                        email: '',
                                        palabrasRestringidas: []
                                    });

                                    setSuccess(false);

                                    setToogleEye({ toogleA: false, toogle1: false, toogle2: false });

                                    if(callBack){
                                        callBack();
                                    }else{
                                         navigate('/perfil/seguridad')
                                    }

                                   
                                }}
                            >
                                Entendido
                            </button>
                        </div>
                    </React.Fragment>

                    :
                    <React.Fragment>
                        <div className="col s12 mt-1">
                            <div className="row validationList">
                                <div className="col s12">


                                    {
                                        data.REGEX_CHECK.map((v, i) => {
                                            return (
                                                <div className="row left-align" key={i}>
                                                    <div className={`col s1 conditionPass ${aplica_reglas() ? '' : v.isCorrect ? "valid" : "invalid"}`}>
                                                        <i className="material-icons left">
                                                            {
                                                                aplica_reglas() ? 
                                                                'adjust'
                                                                : v.isCorrect ? 'check_circle_outline' : 'highlight_off'
                                                        }</i>
                                                    </div>
                                                    <div className={`col s11 conditionPass ${aplica_reglas() ? '' :v.isCorrect ? "valid" : "invalid"}`}>
                                                        <span className="ft-12">{v.text}</span>
                                                    </div>
                                                </div>

                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>



                        <div className="col s12 mt-2">
                            <button type="submit"
                                disabled={data.errorpassword || data.errorpasswordCopy ||
                                    data.password.trim() === "" || data.passwordCopy.trim() === "" || loading
                                }
                                className="btn btn-webSi-primary waves-effect light">Restablecer Contraseña</button>
                        </div>
                    </React.Fragment>
            }

        </form>
    )
}


export default FormNewPass;