import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
    nWithCommas, negativos,
    processMovimientosUtils,
    processFecha,
    processFechaNM, eliminarAcentos, convertirCamelCase,
    getNombreMesAbreviado, currentYear,
    processSaldos, TEXT_FIN_SESION_JWT, capitalizeFirstLetter, processFechaNMGrafica
} from "../utils";
import CardFondo from "../components/cardFondo";
import { ICONS } from '../assets/icons';
import StakedBar from '../components/stakedBar';
import { useNavigate } from "react-router";
import {
    toogleLoad, setSaldosFecha, setSaldosTrimestrales,
    setSaldosMovimientos, setTokenSesion, showModalFinSesion
} from "../redux/slices/appslice";
import { getSaldosFecha, getSaldosTrimestrales, getMovimientos } from "../ws";

function PlanDePensiones() {
    const dispatch = useDispatch()
    const appData = useSelector((state) => state.appData)
    const navigate = useNavigate()

    const [saldosFecha, setSaldosFechaClass] = useState([])
    const [saldosTrimestrales, setSaldosTrimestralesClass] = useState(false);
    const [muestraPortafolio, setMuestraPortafolio] = useState(false);
    const [aportacionesSeparadas, setAportacionesSeparadas] = useState(false);
    const [muestraAportaciones, setMuestraAportaciones] = useState(false);
    const [cargandoMovimientos, setCargandoMovimientos] = useState(false);
    const [cuenta, setCuentaClabe] = useState({
        banco: "",
        clabe: "",
        total: "0.00",
        fchaUActividad: "",
        aportaciones: "0.00",
        rendimientos: "0.00",
        prestamo: "0.00",
        saldoTotal: "0.00",
        retiros: "0.00"
    });

    const [movToShow, setMovToShow] = useState({});

    useEffect(() => {
        dispatch(toogleLoad(true));
        getSF();
        getST();

        if (appData.user && appData.user.Productos) {
            for (let producto of appData.user.Productos) {
                if (producto.TipoCliente.toUpperCase() === 'FONDO DE PENSIONES') {
                    setCuentaClabe({
                        banco: producto.Banco,
                        clabe: producto.Clabe
                    })
                }
            }
        }

        
        getMovi();
       

    }, [])


    function processMovimientos(data) {
        for (const dato of data) {
            if (dato.Producto[0].TipoCliente.toUpperCase() == 'FONDO DE PENSIONES') {
                let movimientos = dato.MovimientoAhorro.concat(dato.MovimientoPrestamo)
                movimientos = JSON.parse(JSON.stringify(movimientos));
                setMovToShow(processMovimientosUtils(movimientos));
            }
        }
    }

    useEffect(() => {
        if (saldosFecha) {
            for (let saldo of saldosFecha) {
                if (saldo.Producto && saldo.Producto.length) {
                    if (saldo.Producto[0].TipoCliente.toUpperCase() === 'FONDO DE PENSIONES') {
                  
                        setCuentaClabe({
                            ...cuenta,
                            total: saldo.TotalLiquidar,
                            fchaUActividad: saldo.FechaOP,
                            aportaciones: saldo.aportaciones,
                            rendimientos: saldo.rendimiento,
                            derechoAdquirido: saldo.DerechoAdquirido,
                            saldoTotal: saldo.SubTotal,
                            retiros: saldo.Retiros
                        })
                    }
                }
            }
        }
    }, [saldosFecha])

    function mostrarPortafolio(saldosTrimestrales) {
        if (saldosTrimestrales && saldosTrimestrales.saldosAgrupadosXPlan) {
            for (let st of saldosTrimestrales.saldosAgrupadosXPlan) {
                if (st.Producto && st.Producto.TipoCliente.toUpperCase() === 'FONDO DE PENSIONES') {
                    if (st.saldos && st.saldos.length && st.saldos.length > 1) {
                        setMuestraPortafolio(true);
                    } else if (st.saldos && st.saldos.length && st.saldos.length === 1) {
                        if (st.saldos[0].plan !== 'NINGUNO') {
                            setMuestraPortafolio(true);
                        }
                    }

                }
            }
        }
    }

    const getMovi = async () => {
             setCargandoMovimientos(true);
            const datosMovimientos = await getMovimientos(appData, () => {
                dispatch(showModalFinSesion({ texto: TEXT_FIN_SESION_JWT }))
            });

            if (datosMovimientos && datosMovimientos.Datos) {
                dispatch(setSaldosMovimientos(datosMovimientos.Datos));
                processMovimientos(datosMovimientos.Datos)
            }else{
                setCargandoMovimientos(false);
            }

            if (datosMovimientos && datosMovimientos.JWT) {
                dispatch(setTokenSesion(datosMovimientos.JWT.current_token))
            }



        
    }


    const getSF = async () => {

        if (!appData.saldosFecha) {

            const saldoFecha = await getSaldosFecha(appData, () => {
                dispatch(showModalFinSesion({ texto: TEXT_FIN_SESION_JWT }))
            });


            if (saldoFecha && saldoFecha.Datos && saldoFecha.Datos.length) {

                setSaldosFechaClass(saldoFecha.Datos)
                dispatch(setSaldosFecha(saldoFecha.Datos));
            }

            if (saldoFecha && saldoFecha.JWT) {
                dispatch(setTokenSesion(saldoFecha.JWT.current_token))
            }

            dispatch(toogleLoad(false));
        } else {
            setSaldosFechaClass(appData.saldosFecha);
            dispatch(toogleLoad(false));
        }
    }

    const getST = async () => {
        dispatch(toogleLoad(true));
        if (!appData.saldosTrimestrales) {
            const saldoTrimestral = await getSaldosTrimestrales(appData, () => {
                dispatch(showModalFinSesion({ texto: TEXT_FIN_SESION_JWT }))
            });

            if (saldoTrimestral && saldoTrimestral.Datos) {
                dispatch(setSaldosTrimestrales(saldoTrimestral.Datos));
                mostrarPortafolio(saldoTrimestral.Datos);
                getAportaciones(saldoTrimestral.Datos);
                setSaldosTrimestralesClass(processSaldos(saldoTrimestral.Datos.saldostrimestrales, 'Fondo de Pensiones'))
            }

            if (saldoTrimestral && saldoTrimestral.JWT) {
                dispatch(setTokenSesion(saldoTrimestral.JWT.current_token))
            }


            dispatch(toogleLoad(false));
        } else {
            setSaldosTrimestralesClass(processSaldos(appData.saldosTrimestrales.saldostrimestrales, 'Fondo de Pensiones'))
            getAportaciones(appData.saldosTrimestrales);
            mostrarPortafolio(appData.saldosTrimestrales);
            dispatch(toogleLoad(false));
        }

    }

    function getAportaciones(saldosTrimestrales) {
        if (saldosTrimestrales && saldosTrimestrales.saldosAgrupadosXCuenta) {

            for (let st of saldosTrimestrales.saldosAgrupadosXCuenta) {
                if (st.Producto && st.Producto.TipoCliente.toUpperCase() === 'FONDO DE PENSIONES') {
                    if (st.saldos && st.saldos.length) {
                      
                        setAportacionesSeparadas(st.saldos);
                    }
                }
            }
        }
    }


    return (
        <div className="container">
            <div className="row">
                <div className="col s12 ft-24 mt-2 paddingLeft0">
                    Fondo de Pensiones
                </div>
                <div className="col s12 m12 l8 xl8 mt-2">
                    <div className="row">
                        <div className="col s12 cardContainer card_fondo">
                            <CardFondo

                                titulo={"Total a Liquidar"}
                                stilo="planPensionCard"
                                cantidad={negativos(`$${nWithCommas(parseFloat(cuenta.total).toFixed(2))}`)}
                                banco={cuenta.banco}
                                clave={cuenta.clabe}
                            />
                        </div>
                        {
                            saldosTrimestrales ?
                                <React.Fragment>
                                    <div className="col s12 wrapperGraficaCalc mt-2">
                                        <div className="col s12 left-align mt-1 txtBold ft-16">
                                            Aportaciones y Rendimientos acumulados
                                        </div>
                                        <StakedBar data={
                                            {
                                                labels: saldosTrimestrales.map((v, i) => {
                                                    
                                                    return processFechaNMGrafica(v.FechaSaldo)
                                                }),
                                                datasets: [

                                                    {
                                                        label: 'Aportaciones*',
                                                        data: saldosTrimestrales.map((v, i) => {
                                                            return v.aportaciones
                                                        }),
                                                        backgroundColor: '#797F97',
                                                    },

                                                    {
                                                        label: 'Rendimientos',
                                                        data: saldosTrimestrales.map((v, i) => {
                                                            return v.rendimiento
                                                        }),
                                                        backgroundColor: '#A6AABA',
                                                    }
                                                ],

                                            }
                                        }
                                        />
                                    </div>
                                    <div className="col s12 right-align ft-12">
                                        *El saldo de las Aportaciones considera Ajustes
                                    </div>
                                </React.Fragment>
                                : null
                        }
                        <div className="col s12 mt-1">
                            <div className="row">
                                <div className="col s12 left-align paddingLeft0">
                                    <span className="ft-18 txtBold">Saldo</span> <span className="ft-12 vlmx-navy-80">Información al {cuenta.fchaUActividad ? processFecha(cuenta.fchaUActividad) : ''}</span>
                                </div>
                                <div className="col s12 mt-1">
                                    <div className="row infoFondo cursorPointer" onClick={() => {
                                        setMuestraAportaciones(!muestraAportaciones)
                                    }}>
                                        <div className="col s6 left-align">
                                            Aportaciones:
                                        </div>
                                        <div className="col s6 right-align">
                                            +${nWithCommas(parseFloat(cuenta.aportaciones).toFixed(2))} <i className="material-icons right" style={{ marginTop: '-3px' }}>
                                                {muestraAportaciones ? 'arrow_drop_up' : 'arrow_drop_down'}
                                            </i>
                                        </div>

                                        {
                                            aportacionesSeparadas && muestraAportaciones ?

                                                <div className="col s12 row" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                    {
                                                        aportacionesSeparadas.map((v, i) => {
                                                            return (
                                                                <div className="row mb-0" key={i} style={{ paddingLeft: '12px', paddingRight: '19px' }}>
                                                                    <div className="col s6 left-align mt-1 ft-12 vlmx-navy-80">
                                                                        {capitalizeFirstLetter(v.concepto)}
                                                                    </div>
                                                                    <div className="col s6 right-align mt-1 ft-12 vlmx-navy-80">
                                                                        ${nWithCommas(parseFloat(v.aportaciones).toFixed(2))}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>

                                                : null
                                        }
                                    </div>
                                    <div className="row infoFondo">
                                        <div className="col s6 left-align">
                                            Rendimientos:
                                        </div>
                                        <div className="col s6 right-align">
                                            +${nWithCommas(parseFloat(cuenta.rendimientos).toFixed(2))}
                                        </div>
                                    </div>
                                    <div className="row infoFondo">
                                        <div className="col s6 left-align">
                                            Ajustes*:
                                        </div>
                                        <div className="col s6 right-align">
                                            {`${negativos(`$${nWithCommas(parseFloat(cuenta.retiros).toFixed(2))}`)}`}
                                        </div>
                                    </div>
                                    <div className="row infoFondo">
                                        <div className="col s6 left-align">
                                        Total:
                                        </div>
                                        <div className="col s6 right-align">
                                            {`${negativos(`$${nWithCommas(parseFloat(cuenta.saldoTotal).toFixed(2))}`)}`}
                                        </div>
                                    </div>
                                    <div className="row infoFondo">
                                        <div className="col s6 left-align">
                                            Derecho Adquirido:
                                        </div>
                                        <div className="col s6 right-align">
                                            {`${negativos(`$${nWithCommas(parseFloat(cuenta.derechoAdquirido).toFixed(2))}`)}`}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col s12 left-align">
                                            <span>* Los Ajustes son efectuados en tu cuenta individual por solicitud de tu empresa, para mayor información acude al centro de contacto ValmexSI</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col s12 m12 l4 xl4 mt-1">
                    <div className="row">
                        <div className="col s12">
                            <button className="btn waves-effect light btnAct white"
                                onClick={() => {
                                    navigate('/movimientos/plandepensiones')
                                }}
                            >Movimientos <img src={ICONS.iconSaldos} alt="prestamos" className="left cIcon"></img></button>
                        </div>

                        {
                            muestraPortafolio ?
                                <div className="col s12 mt-1">
                                    <button className="btn waves-effect light btnAct white"
                                        onClick={() => {
                                            navigate('/portafolioDeInversion/plandepensiones')
                                        }}
                                    >Ver Portafolio<img src={ICONS.iconPortafolio} alt="portafolio de inversión" className="left cIcon"></img></button>
                                </div>

                                : null
                        }

                        <div className="col s12 mt-2">
                            <div className="divider"></div>
                        </div>

                        <div className="col s12 left-align mt-1">
                            Últimos Movimientos
                        </div>

                        {
                            movToShow && Object.keys(movToShow).length ?

                                Object.keys(movToShow).reverse().map((v, i) => {
                                    return (
                                        <React.Fragment key={i}>
                                            <div className="col s12 left-align ft-10 vlmx-navy-80 txtBold mt-1">
                                                {processFechaNM(v)}
                                            </div>
                                            {
                                                movToShow[v].length ?
                                                    movToShow[v].map((d, j) => {

                                                        return (
                                                            <div className="col s12" key={j} style={{ paddingLeft: '20px' }}>
                                                                <div className={`row  mb-0 
                                                        ${d.Monto < 0 || (d.Concepto && eliminarAcentos(d.Concepto.toLowerCase()) === 'prestamo') ? 'tipo_negativo' : 'tipo_normal'}
                                                        `} >
                                                                    <div className="col s6 left-align ft-12 vlmx-navy-80">
                                                                    {
                                                                      convertirCamelCase(d.descrip)
                                                                    }
                                                                    </div>
                                                                    <div className="col s6 right-align ft-14 vlmx-navy-80">
                                                                        {eliminarAcentos(d.Concepto.toLowerCase()) === 'prestamo' ? '-' : ''}   {negativos(`$${nWithCommas(parseFloat(d.Monto).toFixed(2))}`)}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        )
                                                    })
                                                    : null
                                            }
                                        </React.Fragment>
                                    )
                                })



                                : <div style={{ height: '400px' }} >
                                {cargandoMovimientos ?

                                    <div className="col s12 center-align mt-1">
                                        Cargando...
                                    </div> : null}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PlanDePensiones;